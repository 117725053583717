import React from "react";
import "./footer.styles.css";

export const Footer = () => {
  return (
    <div className="ct-footer">
      <div className="ct-big-info-container ct-max-container">
        <div className="ct-big-info">
          <h4>Docs</h4>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://docs.businessbuilders.city"
          >
            Documentation
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://nanuqe.com/businessbuildersnft"
          >
            KYC Verification
          </a>
        </div>
        <div className="ct-big-info">
          <h4>Network</h4>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://t.me/business_builders"
          >
            Telegram <span className="fab fa-telegram"></span>
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://x.com/BBStudiosWeb3"
          >
            Twitter <span className="fab fa-twitter"></span>
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://instagram.com/businessbuildersnft"
          >
            Instagram <span className="fab fa-instagram"></span>
          </a>
        </div>
        <div className="ct-token-footer">
        </div>
      </div>
    </div>
  );
};
