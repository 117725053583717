import React from "react";
import { StaticCityNFT } from "../../molecules/nft/static";

import NuclearFactory from "../../../assets/images/factories/nuclear/3.png";
import EmployeeImage from "../../../assets/images/employees/4_4_4_4.png";

import { StaticEmployeeNFT } from "../../molecules/nft/employee";

import "animate.css";
import "./main.styles.css";

const employeeTypes = ["nuclear", "coal", "water", "food", "wood", "iron"];
const employeeParts = ["head", "body", "foot", "hands"];

export class RelationsMiniGame extends React.PureComponent {
  sessionKey = "gamestepsbusinessbuilders";

  defaultState = {
    onDeployEmployeeExample: false,
    employeeLoader: false,
    onGetContractsExample: false,
    contractsLoader: false,
    onLoadChemistry: false,
    loadChemistry: false,
    onRelationsGame: false,
    loadRelationsGame: false,
    relationsGame: [
      {
        id: 1,
        head: employeeTypes[0],
        body: employeeTypes[1],
        hands: employeeTypes[2],
        foot: employeeTypes[0],
        image: EmployeeImage,
        staticImage: true,
        factory: 1,
      },
      {
        id: 2,
        head: employeeTypes[1],
        body: employeeTypes[0],
        hands: employeeTypes[0],
        foot: employeeTypes[3],
        image: EmployeeImage,
        staticImage: true,
        factory: 1,
      },
      {
        id: 3,
        head: employeeTypes[1],
        body: employeeTypes[0],
        hands: employeeTypes[1],
        foot: employeeTypes[0],
        image: EmployeeImage,
        staticImage: true,
        factory: 1,
      },
    ],
  };

  notValidation = ["relationsGame"];

  constructor(props) {
    super(props);

    this.state = {
      ...this.defaultState,
    };
  }

  changeRelationsGame(type = "add") {
    if (type === "add") {
      this.setState({
        relationsGame: [
          ...this.state.relationsGame,
          this.createRandomRelation(),
        ],
      });
    } else if (type === "remove") {
      if (this.state.relationsGame.length > 1) {
        let relationsGame = [...this.state.relationsGame];
        relationsGame.splice(relationsGame.length - 1, 1);
        this.setState({ relationsGame });
      }
    }
  }

  createRandomRelation() {
    let rand = [];

    for (let i = 0; i < 4; i++) {
      rand.push(
        employeeTypes[Math.floor(Math.random() * employeeTypes.length)]
      );
    }

    return {
      id: this.state.relationsGame.length + 1,
      head: rand[0],
      body: rand[1],
      hands: rand[2],
      foot: rand[3],
      factory: 1,
      image: EmployeeImage,
      staticImage: true,
    };
  }

  deployEmployee() {
    this.updateSteps({ employeeLoader: true, onDeployEmployeeExample: true });
    setTimeout(() => {
      this.updateSteps({
        employeeLoader: false,
      });
    }, 4000);
  }

  loadChemistry() {
    this.updateSteps({
      loadChemistry: true,
      onLoadChemistry: true,
    });
    setTimeout(() => {
      this.updateSteps({
        loadChemistry: false,
      });
    }, 2000);
  }

  loadRelationsGame() {
    this.updateSteps({
      loadRelationsGame: true,
      onRelationsGame: true,
    });
    setTimeout(() => {
      this.updateSteps({
        loadRelationsGame: false,
      });
    }, 2000);
  }

  getContracts() {
    this.updateSteps({ contractsLoader: true, onGetContractsExample: true });
    setTimeout(() => {
      this.updateSteps({
        contractsLoader: false,
      });
    }, 4000);
  }

  componentDidMount() {
    let sessionState = sessionStorage.getItem(this.sessionKey);
    let loaded = false;

    if (sessionState) {
      let parsedState = JSON.parse(sessionState);
      let isValidState = true;
      let stateKeys = Object.keys(this.state);

      for (let i = 0; i < stateKeys.length; i++) {
        if (
          typeof parsedState[stateKeys[i]] !== "boolean" &&
          !this.notValidation.includes(stateKeys[i])
        ) {
          isValidState = false;
          break;
        }
      }

      if (isValidState) {
        loaded = true;
        this.setState({ ...parsedState });
      }
    }

    if (!loaded) {
      sessionStorage.setItem(this.sessionKey, JSON.stringify(this.state));
    }
  }

  updateSteps(steps) {
    let newState = { ...this.state, ...steps };
    sessionStorage.setItem(this.sessionKey, JSON.stringify(newState));
    this.setState(newState);
  }

  reset() {
    this.updateSteps(this.defaultState);
  }

  render() {
    let dynamicBarData = {
      nuclear: 0,
      coal: 0,
      iron: 0,
      wood: 0,
      food: 0,
      water: 0,
    };

    let totalStatistics = 100 * this.state.relationsGame.length;

    for (let i = 0; i < this.state.relationsGame.length; i++) {
      for (let j = 0; j < employeeParts.length; j++) {
        dynamicBarData[this.state.relationsGame[i][employeeParts[j]]] += 25;
      }
    }

    return (
      <div className="ct-game-component ct-max-container">
        <div className="ct-game-exp ct-factories">
          <StaticCityNFT
            className="animate__animated animate__slideInLeft"
            type="Nuclear"
            model="4"
            image={NuclearFactory}
            staticImage
            id={1}
            onDeployEmployee={
              !this.state.onDeployEmployeeExample
                ? () => this.deployEmployee()
                : null
            }
            onDeployEmployeeExample={!this.state.onDeployEmployeeExample}
          />
          <div className="ct-explanation animate__animated animate__slideInRight">
            <h5>
              Factory owners <small>(NFT)</small>{" "}
            </h5>
            <p>
              If you have a factory and 10.000 FUT (Factory utilities Token) you
              can deploy a random Employee.
            </p>
          </div>
        </div>
        {this.state.onDeployEmployeeExample ? (
          <div className="ct-game-exp ct-employee">
            {this.state.employeeLoader ? (
              <div className="ct-loader">
                <div className="spinner"></div>
              </div>
            ) : (
              <React.Fragment>
                <div className="ct-employee-nft">
                  <StaticEmployeeNFT
                    className="animate__animated animate__slideInLeft"
                    image={EmployeeImage}
                    id={1}
                    staticImage
                    head="nuclear"
                    body="nuclear"
                    hands="nuclear"
                    foot={employeeTypes[1]}
                    factory="#1"
                    onGetContract={
                      !this.state.onGetContractsExample
                        ? () => this.getContracts()
                        : null
                    }
                    onGetContractExample={!this.state.onGetContractsExample}
                  />
                  <div className="ct-explanation animate__animated animate__slideInRight">
                    <h5>
                      Employee owner <small>(NFT)</small>{" "}
                    </h5>
                    <p>
                      Now you have an employee, with this you can earn the
                      rewards in FUT (Factory utilities Token). You can sell the
                      employee or buy others.
                    </p>
                  </div>
                </div>

                <div className="ct-factory-relations animate__animated animate__slideInLeft">
                  <h3>Note:</h3>{" "}
                  <p>
                    When you deploy an employee from Factory the employee has a
                    relation.
                  </p>
                  <p>You can change the relation but you need more FUT.</p>
                  <h1>Chemistry</h1>
                  <p>
                    The employee has a specific chemistry with the company so an
                    employee with a good relationship is more valuable to some
                    companies
                  </p>
                  <small>
                    <h3>Contribution to the metaverse: </h3> This is true in the
                    real life, this system is designed to simulate the
                    relationships between employee and company. In the future
                    this data can and will be interconnected with blockchains.
                  </small>
                </div>
              </React.Fragment>
            )}
          </div>
        ) : (
          ""
        )}
        {this.state.onGetContractsExample ? (
          <div className="ct-contracts ct-game-exp ct-mt-20">
            {this.state.contractsLoader ? (
              <div className="ct-loader">
                <div className="spinner"></div>
              </div>
            ) : (
              <React.Fragment>
                <h1>Your employees</h1>
                <div className="ct-employee-statistics">
                  <StaticEmployeeNFT
                    image={EmployeeImage}
                    id={1}
                    staticImage
                    head={employeeTypes[0]}
                    body={employeeTypes[0]}
                    hands={employeeTypes[0]}
                    foot={employeeTypes[1]}
                    factory="#1"
                    onGetContract={
                      !this.state.onGetContractsExample
                        ? () => this.getContracts()
                        : null
                    }
                    onGetContractExample={!this.state.onGetContractsExample}
                  />
                  <div className="ct-statistics">
                    <div className="ct-nuclear">
                      <span>Nuclear</span>
                      <span className="ct-bar ct-bar-75"></span>
                    </div>
                    <div className="ct-coal">
                      <span>Coal</span>
                      <span className="ct-bar ct-bar-25"></span>
                    </div>
                    <div className="ct-water">
                      <span>Water</span>
                      <span className="ct-bar ct-bar-0"></span>
                    </div>
                    <div className="ct-wood">
                      <span>Wood</span>
                      <span className="ct-bar ct-bar-0"></span>
                    </div>
                    <div className="ct-iron">
                      <span>Iron</span>
                      <span className="ct-bar ct-bar-0"></span>
                    </div>
                    <div className="ct-iron">
                      <span>Food</span>
                      <span className="ct-bar ct-bar-0"></span>
                    </div>
                  </div>
                </div>
                <div className="ct-employee-statistics">
                  <StaticEmployeeNFT
                    image={EmployeeImage}
                    id={1}
                    staticImage
                    head={employeeTypes[0]}
                    body={employeeTypes[0]}
                    hands={employeeTypes[1]}
                    foot={employeeTypes[1]}
                    factory="#1"
                    onGetContract={
                      !this.state.onGetContractsExample
                        ? () => this.getContracts()
                        : null
                    }
                    onGetContractExample={!this.state.onGetContractsExample}
                  />
                  <div className="ct-statistics">
                    <div className="ct-nuclear">
                      <span>Nuclear</span>
                      <span className="ct-bar ct-bar-50"></span>
                    </div>
                    <div className="ct-coal">
                      <span>Coal</span>
                      <span className="ct-bar ct-bar-50"></span>
                    </div>
                    <div className="ct-water">
                      <span>Water</span>
                      <span className="ct-bar ct-bar-0"></span>
                    </div>
                    <div className="ct-wood">
                      <span>Wood</span>
                      <span className="ct-bar ct-bar-0"></span>
                    </div>
                    <div className="ct-iron">
                      <span>Iron</span>
                      <span className="ct-bar ct-bar-0"></span>
                    </div>
                    <div className="ct-iron">
                      <span>Food</span>
                      <span className="ct-bar ct-bar-0"></span>
                    </div>
                  </div>
                </div>
                <div className="ct-employee-statistics">
                  <StaticEmployeeNFT
                    image={EmployeeImage}
                    id={1}
                    staticImage
                    head={employeeTypes[0]}
                    body={employeeTypes[0]}
                    hands={employeeTypes[2]}
                    foot={employeeTypes[1]}
                    factory="#1"
                    onGetContract={
                      !this.state.onGetContractsExample
                        ? () => this.getContracts()
                        : null
                    }
                    onGetContractExample={!this.state.onGetContractsExample}
                  />
                  <div className="ct-statistics">
                    <div className="ct-nuclear">
                      <span>Nuclear</span>
                      <span className="ct-bar ct-bar-50"></span>
                    </div>
                    <div className="ct-coal">
                      <span>Coal</span>
                      <span className="ct-bar ct-bar-25"></span>
                    </div>
                    <div className="ct-water">
                      <span>Water</span>
                      <span className="ct-bar ct-bar-25"></span>
                    </div>
                    <div className="ct-wood">
                      <span>Wood</span>
                      <span className="ct-bar ct-bar-0"></span>
                    </div>
                    <div className="ct-iron">
                      <span>Iron</span>
                      <span className="ct-bar ct-bar-0"></span>
                    </div>
                    <div className="ct-iron">
                      <span>Food</span>
                      <span className="ct-bar ct-bar-0"></span>
                    </div>
                  </div>
                </div>
                <h1>Your relations</h1>
                <div className="ct-your-relations">
                  <div className="ct-relations">
                    <div className="ct-factory">
                      <StaticCityNFT
                        className="animate__animated animate__slideInLeft"
                        type={employeeTypes[0]}
                        model="4"
                        image={NuclearFactory}
                        staticImage
                        id={1}
                        onDeployEmployee={
                          !this.state.onDeployEmployeeExample
                            ? () => this.deployEmployee()
                            : null
                        }
                        onDeployEmployeeExample={
                          !this.state.onDeployEmployeeExample
                        }
                      />
                    </div>
                  </div>
                </div>
                <h2>Your Factory has three relations</h2>
                <p>Let's see the chemistry they have.</p>
                {!this.state.onLoadChemistry ? (
                  <button
                    onClick={() => this.loadChemistry()}
                    className="ct-main-button"
                  >
                    Load chemistry
                    <div className="ct-example-click">
                      <span className="ct-click fas fa-mouse-pointer"></span>
                      <span className="ripple"></span>
                    </div>
                  </button>
                ) : (
                  ""
                )}

                {this.state.onLoadChemistry ? (
                  this.state.loadChemistry ? (
                    <div className="ct-loader">
                      <div className="spinner"></div>
                    </div>
                  ) : (
                    <React.Fragment>
                      <div className="ct-factory-relations">
                        <div className="ct-nuclear">
                          <span>Nuclear</span>
                          <span className="ct-bar ct-bar-58"></span>
                        </div>
                        <div className="ct-nuclear">
                          <span>Coal</span>
                          <span className="ct-bar ct-bar-33"></span>
                        </div>
                        <div className="ct-nuclear">
                          <span>Water</span>
                          <span className="ct-bar ct-bar-8"></span>
                        </div>
                      </div>
                      <div className="ct-explanation-relations">
                        <h4>Explanation</h4>
                        <p>
                          You have three related employees then the sum in the
                          nuclear statistic is 300. Now, the sum between the
                          employees with this statistic is 175 so your ratio is
                          175 / 300 or 58%.
                        </p>
                        {!this.state.onRelationsGame ? (
                          <button
                            className="ct-main-button"
                            onClick={() => this.loadRelationsGame()}
                          >
                            Play with relations
                            <div className="ct-example-click">
                              <span className="ct-click fas fa-mouse-pointer"></span>
                              <span className="ripple"></span>
                            </div>
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </React.Fragment>
                  )
                ) : (
                  ""
                )}
              </React.Fragment>
            )}
          </div>
        ) : (
          ""
        )}
        {this.state.onRelationsGame ? (
          <div className="ct-relations-game-container ct-game-exp ct-mt-20">
            {this.state.loadRelationsGame ? (
              <div className="ct-loader">
                <div className="spinner"></div>
              </div>
            ) : (
              <React.Fragment>
                <h1>Relations game</h1>
                <div className="ct-relations-game">
                  {this.state.relationsGame.map((nft, index) => {
                    return <StaticEmployeeNFT key={index} {...nft} />;
                  })}
                </div>
                <div className="ct-relations-game-actions">
                  <button
                    className="ct-main-button ct-mb-5"
                    onClick={() => this.changeRelationsGame("add")}
                  >
                    Add new relation
                  </button>
                  {this.state.relationsGame.length > 1 ? (
                    <button
                      className="ct-main-button"
                      onClick={() => this.changeRelationsGame("remove")}
                    >
                      Remove relation
                    </button>
                  ) : (
                    ""
                  )}
                </div>
                <div className="ct-dynamic-bars">
                  {Object.keys(dynamicBarData).map((employeeType, index) => {
                    return (
                      <div key={index} className={"ct-" + employeeType}>
                        <span>
                          {employeeType} (
                          <span>
                            {(
                              (dynamicBarData[employeeType] / totalStatistics) *
                              100
                            ).toFixed(1)}{" "}
                            %
                          </span>
                          )
                        </span>
                        <div className="ct-dynamic-bar">
                          <span
                            style={{
                              width:
                                (
                                  (dynamicBarData[employeeType] /
                                    totalStatistics) *
                                  100
                                ).toFixed(0) + "%",
                            }}
                          ></span>
                        </div>
                        <small>
                          Your Factory has{" "}
                          {(
                            (dynamicBarData[employeeType] / totalStatistics) *
                            100
                          ).toFixed(1)}
                          % of probabilities to win {employeeType} contract.
                        </small>
                      </div>
                    );
                  })}
                </div>
                <h1>You need better relations to get better business.</h1>
                <small>
                  In this game if your factory has better relations you win more
                  FUT (Factory utilities Token) because you have a better chance
                  of winning
                </small>
              </React.Fragment>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}
