import React from "react";
import { NextUpdateView } from "../molecules/next";
import { PageTitle } from "../molecules/title";
import { PoweredBySummary } from "../molecules/powers/summary";
import { MintComponent } from "../molecules/mint";
import { PlayToEarn } from "../organisms/playToEarn";
import { MainNavigation } from "../organisms/navigation/main";
import { Background } from "../organisms/background";
import { UniverseSimulator } from "../organisms/universe";
import { UnderConstructionUniverse } from "../organisms/universe/underConstruction";
import { NFTBattlesShow, UnrealDestinyShow } from "../organisms/universe/NFTBattlesShow";

import UnityLogo from "../../assets/images/logos/UNITY.svg";
import SolidityLogo from "../../assets/images/logos/SOLIDITY.png";
import AWS from "../../assets/images/logos/AWS.png";
import Meter from "../../assets/images/logos/METER.png";
import { LinksSummary } from "../molecules/powers/links";

export const MainPage = () => {
  return (
    <div className="ct-page-container">
      <MainNavigation
        links={[
          {
            display: "Unreal Destiny",
            type: "external",
            to: "https://unrealdestiny.com",
            disabled: false,
          },
          {
            display: "Official links",
            type: "external",
            to: "https://linktr.ee/businessbuildersnft",
            disabled: false,
          },
        ]}
      />
      <Background>
        <PageTitle />
      </Background>
      <UnrealDestinyShow className="ct-mt-50"></UnrealDestinyShow>
      
      <NextUpdateView customDocs={<a href="https://unrealdestiny.notion.site/" target="_blank" rel="noopener noreferrer">Read documentation</a>} text={"A simulation inspired on the real microeconomy."} title="Used technologies" icon="globe" className="ct-mt-20" />
      <PoweredBySummary
        data={[
          {
            title: "Unity",
            link: "https://unity.com/",
            subtitle: "See used technology",
            image: UnityLogo,
            text: "Built using powerful graphics engines",
          },
          {
            title: "Solidity",
            link: "https://docs.soliditylang.org/en/v0.8.10/",
            subtitle: "See used technology",
            image: SolidityLogo,
            text: "All of our systems are based on integrations with smart contracts.",
          },
          {
            title: "AWS",
            link: "https://aws.amazon.com/",
            subtitle: "See used technology",
            image: AWS,
            text: "We use the best server prodiver to get stability and security.",
          },
          {
            title: "Meter",
            link: "https://meter.io/",
            subtitle: "See used technology",
            image: Meter,
            imageClass: "ct-rare-image",
            text: "The fastest blockchain to provide the better user experience.",
          },
        ]}
      />
      <NFTBattlesShow className="ct-mt-50"></NFTBattlesShow>

      <NextUpdateView customDocs="" text={"We are here to build a better tomorrow."} title="Used technologies" icon="globe" className="ct-mt-20" />
      <PoweredBySummary
        data={[
          {
            title: "Unity",
            link: "https://unity.com/",
            subtitle: "See used technology",
            image: UnityLogo,
            text: "Built using powerful graphics engines",
          },
          {
            title: "Solidity",
            link: "https://docs.soliditylang.org/en/v0.8.10/",
            subtitle: "See used technology",
            image: SolidityLogo,
            text: "All of our systems are based on integrations with smart contracts.",
          },
          {
            title: "AWS",
            link: "https://aws.amazon.com/",
            subtitle: "See used technology",
            image: AWS,
            text: "We use the best server prodiver to get stability and security.",
          },
          {
            title: "Meter",
            link: "https://meter.io/",
            subtitle: "See used technology",
            image: Meter,
            imageClass: "ct-rare-image",
            text: "The fastest blockchain to provide the better user experience.",
          },
        ]}
      />
      <NextUpdateView text={"Building our futures."} title="Project web pages" icon="globe" />
      <LinksSummary
        data={[
          {
            title: "Landing",
            link: "https://nftbattles.businessbuilders.city",
            subtitle: "Landing web page",
            icon: "fas fa-globe",
          },
          {
            title: "DAPP",
            link: "https://nftbattles.businessbuilders.city",
            subtitle: "Under construction",
            icon: "fas fa-globe",
          },
          {
            title: "Items dex",
            link: "https://nftbattles.businessbuilders.city/items",
            subtitle: "Under construction",
            icon: "fas fa-globe",
          },
          {
            title: "Battle field visualizator",
            link: "https://nftbattles.businessbuilders.city/battle-fields",
            subtitle: "Under construction",
            icon: "fas fa-globe",
          },
        ]}
      />
      <UnderConstructionUniverse className="ct-mt-50" />
      <NextUpdateView text={"We are here to build a better tomorrow."} title="Used technologies" icon="globe" className="ct-mt-20" />
      <PoweredBySummary />
    </div>
  );
};
