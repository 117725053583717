import React, { useState } from "react";
import { LeftNavigation, TopNavigation } from ".";

export const MainNavigation = (props) => {
  const [leftNavigation, toggleLeftNavigation] = useState(false);
  return (
    <React.Fragment>
      <TopNavigation
        onToggleLeft={() => toggleLeftNavigation(!leftNavigation)}
        links={props.links}
      />
      <LeftNavigation
        open={leftNavigation}
        onToggleLeft={() => toggleLeftNavigation(!leftNavigation)}
        onClick={() => toggleLeftNavigation(false)}
        links={props.links}
      />
    </React.Fragment>
  );
};
