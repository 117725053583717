import React from "react";

import CoinEmployeeAnimation from "../../../assets/images/employees/coin_animation.gif";

import EmployeeOne from "../../../assets/images/employees/1_1_1_1.png";
import EmployeeTwo from "../../../assets/images/employees/1_4_2_1.png";
import EmployeeThree from "../../../assets/images/employees/1_4_3_5.png";
import EmployeeFour from "../../../assets/images/employees/2_2_2_2.png";
import EmployeeFive from "../../../assets/images/employees/2_3_2_5.png";
import EmployeeSix from "../../../assets/images/employees/2_3_4_5.png";
import EmployeeSeven from "../../../assets/images/employees/3_3_3_3.png";
import EmployeeEight from "../../../assets/images/employees/4_3_2_5.png";
import EmployeeNine from "../../../assets/images/employees/4_3_3_5.png";
import EmployeeTen from "../../../assets/images/employees/4_4_4_4.png";
import EmployeeEleven from "../../../assets/images/employees/5_5_5_5.png";

import "./main.styles.css";

export class PlayToEarn extends React.PureComponent {
  constructor(props) {
    super(props);

    this.employees = [
      EmployeeOne,
      EmployeeTwo,
      EmployeeThree,
      EmployeeFour,
      EmployeeFive,
      EmployeeSix,
      EmployeeSeven,
      EmployeeEight,
      EmployeeNine,
      EmployeeTen,
      EmployeeEleven,
    ];

    this.state = {
      employee:
        this.employees[Math.floor(Math.random() * this.employees.length)],
    };
  }

  generateRandomEmployee() {
    this.setState({
      employee:
        this.employees[Math.floor(Math.random() * this.employees.length)],
    });
  }

  render() {
    return (
      <div className="ct-max-container ct-mt-50 ct-play-to-earn">
        <h1>Earn, learn and play</h1>
        <div className="ct-employee-learning">
          <div className="ct-employee">
            <img src={CoinEmployeeAnimation} alt="BusinessBuilders employee" />
          </div>
          <div className="ct-explanation">
            <h1>Earn, learn and play</h1>
            <h4>Increase your rewards</h4>
            <p>
              Create relations between factories and employees, search the
              better employees for your factories and use your NFTs to simulate
              a great empire.
            </p>
          </div>
        </div>
        <div className="ct-employee-learning">
          <div className="ct-employee ct-rare-employee">
            <img src={this.state.employee} alt="BusinessBuilders employee" />
          </div>
          <div className="ct-explanation">
            <h1>Chainlink VRF</h1>
            <h4>Get random employees to improve your business</h4>
            <p>Use ChainLink VRF to generate secure random employees.</p>
            <button
              className="ct-main-button"
              onClick={() => this.generateRandomEmployee()}
            >
              Generate random
            </button>
          </div>
        </div>
        <div className="ct-employee-learning ct-mb-50">
          <div className="ct-employee ct-rare-employee">
            <img src={EmployeeFour} alt="BusinessBuilders employee" />
          </div>
          <div className="ct-explanation">
            <h1>Unique and rare NFTs</h1>
            <h4>We use progressive upgrade system </h4>
            <p>
              The value of first NFTs increase when we update NFTs database.
              Your rare NFTs will be more rare.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
