import LazyLoad from "react-lazyload";
import { config } from "../../../utils/config";

import "./static.styles.css";

export const StaticCityNFT = (props) => {
  return (
    <div
      className={
        "ct-static-nft" +
        (props.className ? " " + props.className : "") +
        (typeof props.shadow === "boolean"
          ? props.shadow
            ? ""
            : " ct-without-shadow"
          : "")
      }
    >
      <div className="ct-image">
        {props.undefinedImage ? (
          <div className="ct-undefined-image">
            <p>?</p>{" "}
          </div>
        ) : props.lazy ? (
          <LazyLoad height={200}>
            <img
              src={
                props.staticImage ? props.image : config.artHost + props.image
              }
              alt=""
            />
          </LazyLoad>
        ) : (
          <img
            src={props.staticImage ? props.image : config.artHost + props.image}
            alt=""
          />
        )}
      </div>
      <div className="ct-data">
        {props.id ? (
          <span>
            <strong>ID: #</strong> {props.id}
          </span>
        ) : (
          ""
        )}
        {props.type ? (
          <span>
            <strong>Type: </strong> {props.type}
          </span>
        ) : (
          ""
        )}
        {props.model ? (
          <span>
            <strong>Model: </strong> {props.model}
          </span>
        ) : (
          ""
        )}

        {props.probability && !isNaN(props.probability) ? (
          <span>
            <strong>Probability: </strong>{" "}
            {Number(props.probability).toFixed(1)} %
          </span>
        ) : (
          ""
        )}
        {props.multiplier ? (
          <span>
            <strong>Multiplier: </strong> {props.multiplier}
          </span>
        ) : (
          ""
        )}
        {props.growTimeDiference ? (
          <span>
            <strong>Grow time: </strong> {props.nft.growTimeDiferenceInHours}
            Hours
          </span>
        ) : (
          ""
        )}
      </div>
      <div className="ct-actions">
        {props.onDeployEmployee ? (
          <button
            className="ct-main-button ct-deploy-emploee-button"
            onClick={() => props.onDeployEmployee()}
          >
            Deploy employee
            {props.onDeployEmployeeExample ? (
              <div className="ct-example-click">
                <span className="ct-click fas fa-mouse-pointer"></span>
                <span className="ripple"></span>
              </div>
            ) : (
              ""
            )}
          </button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
