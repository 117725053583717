import { Link } from "react-router-dom";
import "./main.styles.css";

export const MiniGames = (props) => {
  return (
    <div className="ct-mini-games ct-max-container">
      <h1>NFT Mini games</h1>
      <h5>
        In all our games you will have to apply your calculation skills to
        obtain the best profitability.
      </h5>
      <p>We are create many games to give value to our NFTs</p>
      <small>
        Our tokens will never be burned, we will create a cyclical economy, that
        is why our token does not have administrator functions, they are not
        necessary when the economy of the system is integrated into the
        permanent movement of the participants. The permanetn movement of the
        token increases the value that the NFT acquiere over time sice strict
        relationship is generated between the flow of the token and the use of
        the NFTs.
      </small>

      <div className="ct-games-container">
        <div className="ct-game ct-small-shadow">
          <h1>RelationsGame</h1>
          <small>In relations game we are simulating company relations.</small>
          <Link className="ct-main-button" to="/relations-game">
            Open tutorial
          </Link>
          <a
            className="ct-main-button"
            href="https://test.businessbuilders.city"
            target="_blank"
            rel="noopener noreferrer"
          >
            Go to DAPP
          </a>
        </div>
        <div className="ct-game ct-small-shadow">
          <h1>NFT World</h1>
          <small>
            Link your NFTs with other NFTs to create small NFT pools. Can you
            build your empire with NFTs.
          </small>
          <small>Comming soon</small>
        </div>
        <div className="ct-game ct-small-shadow">
          <h1>Merge center</h1>
          <small>
            Improve your NFTs. Merge your employees with other employees to get
            one evolved employee.
          </small>
          <small>Comming soon</small>
        </div>
      </div>
    </div>
  );
};
