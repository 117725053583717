import React from "react";
import { RelationsMiniGame } from "../../organisms/game";

import { useState } from "react";

import { TopNavigation, LeftNavigation } from "../../organisms/navigation";

export const RealtionsGamePage = () => {
  const [leftNavigation, toggleLeftNavigation] = useState(false);

  return (
    <React.Fragment>
      <TopNavigation
        onToggleLeft={() => toggleLeftNavigation(!leftNavigation)}
        links={[
          {
            display: "Home",
            type: "internal",
            to: "/",
            disabled: false,
          },
          {
            display: "Mini games",
            type: "internal",
            to: "/games",
            disabled: false,
          },
          {
            display: "Docs",
            type: "external",
            to: "http://docs.businessbuilders.city",
            disabled: false,
          },
          {
            display: "App",
            type: "external",
            to: "http://app.businessbuilders.city",
            disabled: true,
          },
        ]}
      />
      <LeftNavigation
        open={leftNavigation}
        onToggleLeft={() => toggleLeftNavigation(!leftNavigation)}
        onClick={() => toggleLeftNavigation(false)}
        links={[
          {
            display: "Home",
            type: "internal",
            to: "/",
            disabled: false,
          },
          {
            display: "Docs",
            type: "external",
            to: "http://docs.businessbuilders.city",
            disabled: false,
          },
          {
            display: "App",
            type: "external",
            to: "http://app.businessbuilders.city",
            disabled: true,
          },
        ]}
      />
      <RelationsMiniGame />
    </React.Fragment>
  );
};
