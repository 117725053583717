import { Link } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";

import "./main.styles.css";

export const LinksComponent = (props) => {
  return (
    <div className={props.className}>
      {props.links?.map((link, index) => {
        if (link.type === "scroll") {
          return (
            <Link key={index} offset={-60} to={link.to}>
              {link.display}
            </Link>
          );
        } else if (link.type === "external") {
          return !link.disabled ? (
            <a
              key={index}
              target="_blank"
              rel="noopener noreferrer"
              href={link.to}
            >
              {link.display}{" "}
              {link.icon ? <span className={link.icon}></span> : ""}
            </a>
          ) : (
            <span key={index} className="ct-disabled-link">
              {link.display}
            </span>
          );
        } else if (link.type === "internal") {
          return !link.disabled ? (
            <RouterLink key={index} exact="true" to={link.to}>
              {link.display}
              {link.icon ? <span className={link.icon}></span> : ""}
            </RouterLink>
          ) : (
            <span key={index} className="ct-disabled-link">
              {link.display}
            </span>
          );
        }

        return "";
      })}
    </div>
  );
};
