import OneFactory from "../../../assets/images/factories/nuclear/1.png";
import TwoFactory from "../../../assets/images/factories/nuclear/2.png";
import ThreeFactory from "../../../assets/images/factories/wood/1.png";

import "./main.styles.css";

export const MiniServices = () => {
  return (
    <div className="ct-mini-services ct-max-container">
      <h1>Mini NFT services</h1>
      <small>
        We are building a cyclical economy so one game is not enough, we want to
        integrate a series of services with which we can improve the flow of
        tokens and increase the value of the system.
      </small>
      <div className="ct-services">
        <div className="ct-service ct-builders-service">
          <h1>Factory builder service</h1>
          <small>
            Transform real factory into game assets (Metaverse projection).
          </small>
          <div className="ct-factories">
            <img src={OneFactory} alt="Businessbuilders nuclear factory" />
            <img src={TwoFactory} alt="Businessbuilders nuclear factory" />
            <img src={ThreeFactory} alt="Businessbuilders wood factory" />
          </div>
          <div className="ct-characteristics">
            <ul>
              <li>
                Your factory will be in our marketplace (Earn tokens with all
                NFT sells)
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
