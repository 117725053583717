import { MainNavigation } from "../organisms/navigation/main";

export const MarketplacePage = () => {
  return (
    <div className="ct-collection-page">
      <MainNavigation
        links={[
          {
            display: "Home",
            type: "internal",
            to: "/",
            disabled: false,
          },
          {
            display: "Collection",
            type: "internal",
            to: "/collection",
            disabled: false,
          },
          {
            display: "Docs",
            type: "external",
            to: "http://docs.businessbuilders.city",
            disabled: false,
          },
          {
            display: "App",
            type: "external",
            to: "http://app.businessbuilders.city",
            disabled: true,
          },
        ]}
      />
    </div>
  );
};
