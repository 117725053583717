import { LinksComponent } from "../../molecules/links";

import "./left.styles.css";

export const LeftNavigation = (props) => {
  return (
    <div className={"ct-left-nav" + (props.open ? " ct-open" : "")}>
      <LinksComponent className="ct-links" links={props.links} />
      <div className="ct-social">
        <a
          href="https://x.com/BBStudiosWeb3"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="fab fa-twitter"></span>
        </a>
        <a
          href="https://t.me/business_builders_en"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="fab fa-telegram"></span>
        </a>
        <a
          href="https://instagram.com/businessbuildersnft"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="fab fa-instagram"></span>
        </a>
      </div>
    </div>
  );
};
