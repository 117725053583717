import "./main.styles.css";

import Video from "../../../assets/videos/1.mp4";

export const Background = (props) => {
  return (
    <div className="ct-background">
      <video autoPlay loop muted>
        <source src={Video} type="video/mp4" />
      </video>
      <audio src=""></audio>
      {props.children}
    </div>
  );
};
