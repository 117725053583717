import React from "react";
import { config } from "../../../utils/config";
import { StaticCityNFT } from "../../molecules/nft/static";
import { NextUpdateView } from "../../molecules/next";

import "./main.styles.css";

export class Collection extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      collections: [],
      factories: [],
      types: [],
      head: 1,
      body: 1,
      legs: 1,
      hands: 1,
    };
  }

  componentDidMount() {
    fetch(config.artHost + "/factories-data")
      .then((data) => data.json())
      .then((factoriesData) => this.setState({ ...factoriesData }))
      .catch((error) => console.error(error));
  }

  render() {
    let employeePoints = 1;

    const totalPoints = this.state.types.reduce(
      (total, type) => (total += type.position),
      0
    );

    const legs = this.state.types.find((type) => type.id === this.state.legs);
    const hands = this.state.types.find((type) => type.id === this.state.hands);
    const body = this.state.types.find((type) => type.id === this.state.body);

    if (legs && legs.id === this.state.head) {
      employeePoints += Number(
        Math.floor(totalPoints / legs.position || 0).toFixed(0)
      );
    }

    if (hands && hands.id === this.state.head) {
      employeePoints += Number(
        Math.floor(totalPoints / hands.position || 0).toFixed(0)
      );
    }

    if (body && body.id === this.state.head) {
      employeePoints += Number(
        Math.floor(totalPoints / body.position || 0).toFixed(0)
      );
    }

    return (
      <div className="ct-max-container ct-collection-component">
        <NextUpdateView
          title="Open sea collections"
          icon="link"
          text="You can sell or buy NFT on the largest NFT marketplace"
          customDocs={
            <React.Fragment>
              <a
                href="https://opensea.io/collection/businessbuilders-factories"
                target="_blank"
                rel="noopener noreferrer"
              >
                Factories Collection
              </a>
              <a
                href="https://opensea.io/collection/businessbuilders-employees"
                target="_blank"
                rel="noopener noreferrer"
              >
                Employees Collection
              </a>
              <a
                href="https://opensea.io/collection/businessbuilders-multiemployees"
                target="_blank"
                rel="noopener noreferrer"
              >
                Multi Employees Collection
              </a>
              <a
                href="https://opensea.io/collection/businessbuilders-childrenemployee"
                target="_blank"
                rel="noopener noreferrer"
              >
                Childrens Collection
              </a>
            </React.Fragment>
          }
        />

        <NextUpdateView
          title="TofuNFT collections"
          icon="link"
          text="Use tofu in the meter network to trade your NFTs"
          customDocs={
            <React.Fragment>
              <a
                href="https://tofunft.com/collection/businessbuilders/items"
                target="_blank"
                rel="noopener noreferrer"
              >
                Factories Collection
              </a>
              <a
                href="https://tofunft.com/collection/businessbuilders-employees/items"
                target="_blank"
                rel="noopener noreferrer"
              >
                Employees Collection
              </a>
              <a
                href="https://tofunft.com/collection/businessbuilders%20multiemployees/items"
                target="_blank"
                rel="noopener noreferrer"
              >
                Multi Employees Collection
              </a>
              <a
                href="https://tofunft.com/collection/businessbuilders-miniemployees/items"
                target="_blank"
                rel="noopener noreferrer"
              >
                Childrens Collection
              </a>
              <a
                href="https://tofunft.com/collection/businessbuilders-cities/items"
                target="_blank"
                rel="noopener noreferrer"
              >
                Cities Collection
              </a>
            </React.Fragment>
          }
        />

        <NextUpdateView
          title="Employee builder"
          icon="link"
          text="Search the best employee."
        />
        <div className="ct-employee-builder">
          <div className="ct-employee">
            <img
              src={
                "https://art.businessbuilders.city/static/employees/" +
                this.state.head +
                "_" +
                this.state.body +
                "_" +
                this.state.legs +
                "_" +
                this.state.hands +
                ".png"
              }
              alt=""
            />
          </div>
          <div className="ct-selector">
            <div className="ct-selection">
              <label htmlFor="head">Head</label>
              <select
                name=""
                id=""
                onChange={(e) =>
                  this.setState({
                    head: Number(
                      e.target.options[e.target.selectedIndex].value
                    ),
                  })
                }
              >
                {this.state.types.map((type) => {
                  return (
                    <option key={type.id + "" + type.name} value={type.id}>
                      {type.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="ct-selection">
              <label htmlFor="body">Body</label>
              <select
                name=""
                id=""
                onChange={(e) =>
                  this.setState({
                    body: Number(
                      e.target.options[e.target.selectedIndex].value
                    ),
                  })
                }
              >
                {this.state.types.map((type) => {
                  return (
                    <option key={type.id + "" + type.name} value={type.id}>
                      {type.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="ct-selection">
              <label htmlFor="legs">Legs</label>
              <select
                name=""
                id=""
                onChange={(e) =>
                  this.setState({
                    legs: Number(
                      e.target.options[e.target.selectedIndex].value
                    ),
                  })
                }
              >
                {this.state.types.map((type) => {
                  return (
                    <option key={type.id + "" + type.name} value={type.id}>
                      {type.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="ct-selection">
              <label htmlFor="hands">Hands</label>
              <select
                name=""
                id=""
                onChange={(e) =>
                  this.setState({
                    hands: Number(
                      e.target.options[e.target.selectedIndex].value
                    ),
                  })
                }
              >
                {this.state.types.map((type) => {
                  return (
                    <option key={type.id + "" + type.name} value={type.id}>
                      {type.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="ct-data">Points: {employeePoints}</div>
          </div>
        </div>
        <NextUpdateView
          title="NFT updates"
          icon="link"
          text="We constantly update the probabilities of the NFT. The probability of new factories will be greater than the previous ones, so the first NFTs, if you save them, increase their value. "
        />
        <div className="ct-collection-grid">
          {this.state.factories?.map((collection, index) => {
            return (
              <div key={index} className="ct-collection-div">
                <h4>
                  {collection.type}
                  <span>
                    {" ( " + collection.probability?.toFixed(1) + "% )"}
                  </span>
                </h4>
                <div className="ct-collection-space">
                  {collection?.models?.map((nft, index) => (
                    <StaticCityNFT {...nft} key={index} />
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
