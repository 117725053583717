import React from "react";

import SimulatorVideo from "../../../assets/videos/2.mp4";

import "./main.styles.css";

export class UniverseSimulator extends React.PureComponent {
  render() {
    return (
      <div className="ct-max-container ct-mt-50 ct-cities">
        <div className="ct-video">
          <video autoPlay controls muted>
            <source src={SimulatorVideo} />
          </video>
        </div>
        <div className="ct-cities-simulator">
          <h1>Simulate your empire in our cities system.</h1>
          <h4>Play with your NFTs </h4>
          <ul>
            <li>Send your NFTs to the universities and improve it</li>
            <li>
              Create the best relations with your employees in other users
              factories
            </li>
            <li>Build your factories in the cities and earn FTB passively</li>
            <li>
              Manage your own city and get resources while other players play in
              your lands
            </li>
          </ul>
          <h1>Request or build your own 3D models.</h1>
          <h4>Community managers</h4>
          <ul>
            <li>
              If you have a community with more than 100 users you can request
              your own structure and we are going to add in you city
            </li>
            <li>
              Add the logo and all links to your community and engage more users
            </li>
            <li>
              If you are an NFT creator and you have your own city you can
              request you own university
            </li>
            <li>
              Soon, real structues simulation. The bridge from the real life to
              the metaverse
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
