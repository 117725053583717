import "./main.styles.css";
import { TypeAnimation } from "react-type-animation";

export const PageTitle = () => {
  return (
    <div className="ct-page-title">
      <div className="ct-title-container">
        <h1>BusinessBuilders</h1>
        <TypeAnimation
          sequence={["Build your NFT empire", 4000, "Develop your assets realm", 4000, "Dominate the market", 4000, "Rule the NFT universe", 4000]}
          wrapper="h4"
          repeat={Infinity}
        ></TypeAnimation>
      </div>
    </div>
  );
};
