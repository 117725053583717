import LazyLoad from "react-lazyload";
import MaintenanceVideo from "../../../assets/videos/3.mp4";
import { TypeAnimation } from "react-type-animation";

import styles from "./underConstruction.module.css";

export const UnderConstructionUniverse = (props) => {
  return (
    <LazyLoad>
      <div className={styles.ct_under_construction + " ct-max-container " + props.className}>
        <video autoPlay loop muted>
          <source src={MaintenanceVideo} type="video/mp4" />
        </video>
        <div className={styles.ct_background}>
          <TypeAnimation
            sequence={["City builder", 3000, "NFT Ecosystem", 3000, "Digital assets", 3000, "Meter network", 3000]}
            wrapper="h2"
            speed={20}
            repeat={Infinity}
          ></TypeAnimation>
          <h4>Under maintenance</h4>
          <p>Soon we will enable all the systems.</p>
        </div>
      </div>
    </LazyLoad>
  );
};
