import BBLogo from "../../../assets/images/logos/BB_LOGO.svg";

import { LinksComponent } from "../../molecules/links";

import "./top.styles.css";

export const TopNavigation = (props) => {
  return (
    <div className="ct-top-nav">
      <div className="ct-max-container">
        <div className="ct-title">
          <img className="ct-page-logo" src={BBLogo} alt="" />
          <div className="ct-text">
            <h1>BusinessBuilders</h1>
            <small>Ecosystems</small>
          </div>
        </div>
        <div className="ct-links">
          <LinksComponent className="ct-principals" links={props.links} />
          <div className="ct-actions">
            <a
              href="https://x.com/BBStudiosWeb3"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="fab fa-twitter"></span>
            </a>
            <a
              href="https://t.me/business_builders_en"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="fab fa-telegram"></span>
            </a>
            <a
              href="https://instagram.com/businessbuildersnft"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="fab fa-instagram"></span>
            </a>
            <span
              className="fas fa-bars"
              onClick={() => props.onToggleLeft()}
            ></span>
          </div>
        </div>
      </div>
    </div>
  );
};
