import LazyLoad from "react-lazyload";
import "./main.styles.css";

export const NextUpdateView = (props) => {
  return (
    <LazyLoad height={200}>
      <div className={"ct-next-update ct-max-container animate__animated animate__slideInLeft " + props.className}>
        <h3>{props.title}</h3>
        {props.text ? <p>{props.text}</p> : ""}
        {props.customDocs ? (
          props.customDocs
        ) : (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://docs.businessbuilders.city/"
          >
            Read documentation
          </a>
        )}
        {props.version ? (
          <h5>
            {props.version} <p>Next update</p>
          </h5>
        ) : (
          ""
        )}
        <span
          className={"fas fa-" + (props.icon ? props.icon : "project-diagram")}
        ></span>
      </div>
    </LazyLoad>
  );
};
