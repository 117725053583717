import ChainLinkLogo from "../../../assets/images/logos/CHAINLINK.svg";
import SolidityLogo from "../../../assets/images/logos/SOLIDITY.png";
import BinanceSmartChain from "../../../assets/images/logos/BINANCESMARTCHAIN.png";
import Polygon from "../../../assets/images/logos/POLYGON.svg";
import Meter from "../../../assets/images/logos/METER.png";
import AWS from "../../../assets/images/logos/AWS.png";

import "./summary.css";

const defaultInfo = [
  {
    title: "ChainLink",
    link: "https://docs.chain.link/docs/chainlink-vrf/",
    image: ChainLinkLogo,
    text: "We use Chainlink to generate secure random numbers with VRF (Verificable random function).",
  },
  {
    title: "Solidity",
    link: "https://docs.soliditylang.org/en/v0.8.10/",
    image: SolidityLogo,
    text: "All of our systems are based on integrations with smart contracts.",
  },
  {
    title: "AWS",
    link: "https://aws.amazon.com/",
    image: AWS,
    text: "We use the best server prodiver to get stability and security.",
  },
  {
    title: "Meter",
    link: "https://meter.io/",
    image: Meter,
    text: "The fastest blockchain to provide the better user experience.",
    imageClass: "meterImage"
  },
];

export const PoweredBySummary = (props) => {
  return (
    <div className="ct-powered-summary ct-max-container">
      <div className="ct-info-container">
        {(Array.isArray(props.data) ? props.data : defaultInfo).map((info) => {
          return (
            <div className="ct-info">
              <div className="ct-title">
                <h1>
                  {info.title}
                  <small>
                    <a href={info.link} target="_blank" rel="noopener noreferrer">
                      {info.subtitle}
                    </a>
                  </small>
                </h1>
                <a className={info.imageClass} target="_blank" rel="noopener noreferrer" href={info.link}>
                  {info.image ? <img src={info.image} alt="" /> : <span className={info.icon ? info.icon : "fas fa-globe"}></span>}
                </a>
              </div>
              {info.text ? <p>{info.text}</p> : ""}
            </div>
          );
        })}
      </div>
    </div>
  );
};
