import { MainNavigation } from "../organisms/navigation/main";
import { MiniServices } from "../organisms/services";

export const MiniServicesPage = (props) => {
  return (
    <div className="ct-services-page">
      <MainNavigation
        links={[
          {
            display: "Home",
            type: "internal",
            to: "/",
            disabled: false,
          },
          {
            display: "Collection",
            type: "internal",
            to: "/collection",
            disabled: false,
          },
          {
            display: "Mini games",
            type: "internal",
            to: "/games",
            disabled: false,
          },
          {
            display: "Docs",
            type: "external",
            to: "http://docs.businessbuilders.city",
            disabled: false,
          },
          {
            display: "App",
            type: "external",
            to: "http://app.businessbuilders.city",
            disabled: true,
          },
        ]}
      />
      <MiniServices />
    </div>
  );
};
