import { Collection } from "../organisms/collection";

import { MainNavigation } from "../organisms/navigation/main";

export const CollectionPage = () => {
  return (
    <div className="ct-collection-page">
      <MainNavigation
        links={[
          {
            display: "Home",
            type: "internal",
            to: "/",
            disabled: false,
          },
          {
            display: "Universe",
            type: "external",
            to: "https://universe.businessbuilders.city",
            disabled: false,
          },
          {
            display: "Docs",
            type: "external",
            to: "http://docs.businessbuilders.city",
            disabled: false,
          },
          {
            display: "App",
            type: "external",
            to: "http://app.businessbuilders.city",
            disabled: true,
          },
        ]}
      />
      <Collection />
    </div>
  );
};
