import LazyLoad from "react-lazyload";
import { config } from "../../../utils/config";

import "./employee.styles.css";

export const StaticEmployeeNFT = (props) => {
  return (
    <div
      className={
        "ct-static-nft" +
        (props.className ? " " + props.className : "") +
        (typeof props.shadow === "boolean"
          ? props.shadow
            ? ""
            : " ct-without-shadow"
          : "")
      }
    >
      <div className="ct-image">
        {props.undefinedImage ? (
          <div className="ct-undefined-image">
            <p>?</p>{" "}
          </div>
        ) : props.lazy ? (
          <LazyLoad height={200}>
            <img
              src={
                props.staticImage ? props.image : config.artHost + props.image
              }
              alt=""
            />
          </LazyLoad>
        ) : (
          <img
            src={props.staticImage ? props.image : config.artHost + props.image}
            alt=""
          />
        )}
      </div>
      <div className="ct-data">
        {props.id ? (
          <span>
            <strong>ID: #</strong> {props.id}
          </span>
        ) : (
          ""
        )}
        {props.head ? (
          <span>
            <strong>Head: </strong> {props.head}
          </span>
        ) : (
          ""
        )}
        {props.body ? (
          <span>
            <strong>Body: </strong> {props.body}
          </span>
        ) : (
          ""
        )}

        {props.hands ? (
          <span>
            <strong>Hands: </strong> {props.hands}
          </span>
        ) : (
          ""
        )}

        {props.foot ? (
          <span>
            <strong>Foot: </strong> {props.foot}
          </span>
        ) : (
          ""
        )}
        {props.factory ? (
          <span>
            <strong>Factory: </strong> {props.factory}
          </span>
        ) : (
          ""
        )}
      </div>
      <div className="ct-actions">
        {props.onGetContract ? (
          <button
            className="ct-main-button ct-deploy-emploee-button"
            onClick={() => props.onGetContract()}
          >
            Get contracts
            {props.onGetContractExample ? (
              <div className="ct-example-click">
                <span className="ct-click fas fa-mouse-pointer"></span>
                <span className="ripple"></span>
              </div>
            ) : (
              ""
            )}
          </button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
