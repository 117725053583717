import React from "react";

import { BrowserRouter } from "react-router-dom";
import { RouterContainer } from "./router";

function App() {
  return (
    <BrowserRouter>
      <RouterContainer />
    </BrowserRouter>
  );
}

export default App;
