import LazyLoad from "react-lazyload";
import NFTBattles from "../../../assets/videos/4.mp4";
import UnrealDestinyVideo from "../../../assets/videos/5.mp4";
import { TypeAnimation } from "react-type-animation";

import styles from "./underConstruction.module.css";

export const NFTBattlesShow = (props) => {
  return (
    <LazyLoad>
      <div className={styles.ct_under_construction + " ct-max-container " + props.className}>
        <video autoPlay loop muted>
          <source src={NFTBattles} type="video/mp4" />
        </video>
        <div className={styles.ct_background}>
          <TypeAnimation sequence={["NFT Battles", 3000, "NFTs", 3000, "Gaming", 3000]} wrapper="h2" speed={20} repeat={Infinity}></TypeAnimation>
          <h4>Under construction</h4>
        </div>
      </div>
    </LazyLoad>
  );
};



export const UnrealDestinyShow = (props) => {
  return (
    <LazyLoad>
      <div className={styles.ct_under_construction + " ct-max-container " + props.className}>
        <video autoPlay loop muted>
          <source src={UnrealDestinyVideo} type="video/mp4" />
        </video>
        <div className={styles.ct_background}>
          <TypeAnimation sequence={["Unreal Destiny", 3000, "Video Game", 3000, "Gaming", 3000]} wrapper="h2" speed={20} repeat={Infinity}></TypeAnimation>
          <h4>Open Beta</h4>
        </div>
      </div>
    </LazyLoad>
  );
};
