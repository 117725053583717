import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import { Switch, Route } from "react-router-dom";
import { CollectionPage } from "./pages/collections";
import { MainPage } from "./pages/main";

import { GamePage } from "./pages/games";
import { RealtionsGamePage } from "./pages/subpages/relations-game";
import { MiniServicesPage } from "./pages/services";
import { Footer } from "./organisms/navigation";
import { MarketplacePage } from "./pages/market";

const RouterContainerUnlink = ({ history }) => {
  const [loader, activeLoader] = useState(true);

  useEffect(() => {
    const routerListener = history.listen(() => {
      window.scrollTo(0, 0);
      activeLoader(true);
    });

    return () => {
      if (routerListener) routerListener();
    };
  }, [history]);

  if (loader) {
    window.scrollTo(0, 0);
    document.body.style.overflow = "hidden";
    setTimeout(() => activeLoader(false), 2500);
  } else document.body.style.overflow = "auto";

  return (
    <div className={"ct-app-container" + (loader ? " ct-disable-page" : "")}>
      {loader ? (
        <div className="ct-page-loader">
          <div className="spinner"></div>
          <h1>BusinessBuilders</h1>
          <small>Build your NFT empire.</small>
        </div>
      ) : (
        ""
      )}
      <Switch>
        <Route exact path="/">
          <MainPage />
        </Route>
        <Route exact path="/collection">
          <CollectionPage />
        </Route>
        <Route exact path="/games">
          <GamePage />
        </Route>
        <Route exact path="/services">
          <MiniServicesPage />
        </Route>
        <Route exact path="/relations-game">
          <RealtionsGamePage />
        </Route>
        <Route exact path="/market">
          <MarketplacePage />
        </Route>
      </Switch>
      <Footer />
    </div>
  );
};

export const RouterContainer = withRouter(RouterContainerUnlink);
