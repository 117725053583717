import styles from "./links.module.css";

import "./summary.css";

const defaultInfo = [
  {
    title: "ChainLink",
    link: "https://docs.chain.link/docs/chainlink-vrf/",
    icon: "fas fa-globe",
  },
];

export const LinksSummary = (props) => {
  return (
    <div className={styles.links_container + " ct-max-container"}>
      {(Array.isArray(props.data) ? props.data : defaultInfo).map((info) => {
        return (
          <div className="ct-info">
            <div className="ct-title">
              <h1>
                <span>{info.title}</span>{" "}
                <a className={info.imageClass} target="_blank" rel="noopener noreferrer" href={info.link}>
                  <span className={info.icon ? info.icon : "fas fa-globe"}></span>
                </a>
              </h1>
              <a href={info.link} target="_blank" rel="noopener noreferrer">
                {info.subtitle}
              </a>
              <a href={info.link} target="_blank" rel="noopener noreferrer">
                {info.link}
              </a>
            </div>
          </div>
        );
      })}
    </div>
  );
};
